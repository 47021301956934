<template>
  <div>
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item label="体检完成">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button value="4">
            服务完成(完成)
          </a-radio-button>
          <a-radio-button value="5">
            服务取消(完成)
          </a-radio-button>
          <a-radio-button value="6">
            服务爽约(完成)
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>

      <a-form-model-item
        :maxLength="500"
        label="现场服务小结"
      >
        <a-textarea
          :rows="4"
          v-model="form.finishRemarks"
          name="finishRemarks"
          placeholder="现场服务小结"/>
      </a-form-model-item>
    </a-form-model>
    <a-card >
      <a-descriptions title="订单信息" :column="2">
        <a-descriptions-item label="订单号">
          {{ info.orderSn }}
        </a-descriptions-item>
        <a-descriptions-item label="B端渠道单号">
          {{ info.pingaOrderSn }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card >
      <a-descriptions title="申请信息" :column="2">
        <a-descriptions-item label="B端渠道">
          {{ info.channelName }}
        </a-descriptions-item>
        <a-descriptions-item label="B端服务产品">
          {{ info.channelProduct }}
        </a-descriptions-item>
        <a-descriptions-item label="内部一级产品">
          {{ info.productName }}
        </a-descriptions-item>
        <a-descriptions-item label="内部二级产品">
          {{ info.productChildName }}
        </a-descriptions-item>
        <a-descriptions-item label="姓名">
          {{ info.truename }}
        </a-descriptions-item>
        <a-descriptions-item label="身份证号">
          {{ info.idcard }}
        </a-descriptions-item>
        <a-descriptions-item label="手机号码">
          {{ info.mobile }}
        </a-descriptions-item>
        <a-descriptions-item label="客户信息备注">
          {{ info.remarks }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
    <a-card >
      <a-descriptions title="录入信息" :column="2">
        <a-descriptions-item label="使用地区">
          {{ info.regionName }}
        </a-descriptions-item>
        <a-descriptions-item label="意向医院">
          {{ info.nosoName }}
        </a-descriptions-item>
        <a-descriptions-item label="意向科室">
          {{ info.depName }}
        </a-descriptions-item>
        <a-descriptions-item label="对应内部科室">
          {{ info.sectionName }}- {{ info.sectionSecName }}
        </a-descriptions-item>

        <a-descriptions-item label="预约医生">
          {{ info.doctorName }}
        </a-descriptions-item>

        <a-descriptions-item label="医生职称">
          {{ info.doctorPosition }}- {{ info.doctorLecturerName }}
        </a-descriptions-item>
        <a-descriptions-item label="医院地址">
          {{ info.address }}
        </a-descriptions-item>

        <a-descriptions-item label="预约时间">
          {{ info.subscribeDate|formatterDateTime }}
        </a-descriptions-item>

        <a-descriptions-item label="陪诊人员">
          {{ info.escortName }}
        </a-descriptions-item>
        <a-descriptions-item label="注意事项">
          {{ info.descinfo }}
        </a-descriptions-item>
      </a-descriptions>
    </a-card>
  </div>

</template>
<script>
import { formatterDate, formatterDateTime } from '@/utils/util'
import { getById, updateFinishStatus } from '@/api/order'
export default {
  filters: {
    formatterDateTime (msg) {
      return formatterDateTime(msg)
    },
    dataFormat (msg) {
      return formatterDate(msg)
    }
  },
  data () {
    return {
      orderId: 0,
      info: {
        truename: undefined
      },
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        status: 0
      }
    }
  },
  methods: {
    setOrderId (orderId) {
      this.orderId = orderId
      getById({ orderId: orderId })
        .then(res => {
          console.log(res)
          this.info = res.result.info
        })
    },
    onSubmit () {
      const self = this
      if (this.form.status > 0) {
        updateFinishStatus({
          orderId: self.orderId,
          status: self.form.status,
          finishRemarks: self.form.finishRemarks
        }).then(res => {
          if (res.code === 1) {
            self.$message.info('修改成功')
            self.$emit('finishishuxin')
          } else {
            self.$message.error(res.message)
          }
        })
      } else {
        self.$message.error('请选择完成状态')
      }
    }
  }

}
</script>
